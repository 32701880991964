<template>

  <section id="dashboard" class="mt-4">

    <div class="container">
        <div class="row">

            <SideNav v-bind:selected="4"/>

            <!-- SECOND HALF -->

            <div class="col-xs-12 col-sm-12 col-md-12 mt-md-0">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Method</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="entry in this.account.account.walletHistory" v-bind:key="entry.tid">
                      <th scope="row">{{entry.tid}}</th>
                      <td>{{entry.method}}</td>
                      <td>{{new Date(entry.date).toISOString().slice(0,10)}}</td>
                      <td>{{entry.amount}}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
    </div>
  </section>
  <Footer/>
</template>

<script>


import Footer from '../../components/global/Footer'
import SideNav from '../../components/dashboard/SideNavigator'

export default {
    components: {
        Footer,
        SideNav
    },
    props: {
      account: Object
    }
}
</script>